@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
  --white: #fff;
  --black: #000;

}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 1rem;
}

h2,
.h2 {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
}

.flex {
  display: flex;
}

.navbar {
  border-bottom: 1px solid #1A1A1A;
  background: rgba(0, 0, 0) !important;
  backdrop-filter: blur(20px);
  width: 100%;
  top: 0px;
  z-index: 10;
}

.nav-link {
  color: var(--white, #FFF);
  font-family: Inter;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  padding: .5rem 1.25rem !important;
}

.nav-link:hover,
.nav-link:focus,
.nav-link:active, .nav-link.show {
  color: #ECBF67;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
  color: #ECBF67;
}
.dropdown-menu{
  padding: 0;
  background: transparent;
  border-radius: 0.75rem;
  border: 0;
}
.dropdown-menu .blur{
  border-radius: 0.75rem;
  background: rgba(0, 0, 0, 0.96);
  box-shadow: 0px 116px 164px 0px rgba(0, 0, 0, 0.40);
  backdrop-filter: blur(20px);
  padding:.5rem;
  transition: all .5s;
  border: 1px solid #181818;
  
}

.dropdown-menu[data-bs-popper]{
  top:calc(100% + 1rem)
}
.dropdown-item{
  margin:0;
  color: rgba(197, 197, 197, 0.80);
  font-size: 1rem;
  line-height: 1.5;
  min-width: 11rem;
  background-position: calc(100% - 1rem);
  background-repeat: no-repeat;
  padding-right: 2rem;
  transition: all .5s;
  padding: 0.5rem 2rem 0.5rem 1rem;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--white);
  background-color: transparent;
  background: url("../public/arrow-light.svg");
  background-position: calc(100% - .5rem);
  background-repeat: no-repeat;
  transition: all .5s;
  background-color: #202020;
  border-radius: .5rem;
}
.dropdown-item:last-child{
  margin:0;
}

.dropdown-toggle::after {
  display: none;
}

.btn {
  padding: .5rem 1.5rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: .5rem;
  transition: all .5s;
}
.btn svg{
  transition: all .5s;
  margin-left: .5rem;
  margin-top: 2px;
}
.btn:hover{
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 7px 40px 0px rgba(0, 0, 0, 0.10), 0px 30px 40px 0px rgba(0, 0, 0, 0.09), 0px 67px 40px 0px rgba(0, 0, 0, 0.05), 0px 120px 48px 0px rgba(0, 0, 0, 0.01), 0px 187px 52px 0px rgba(0, 0, 0, 0.00);
}
.btn:hover svg{
  transform:translateX(4px);
}

.btn-lg {
  font-size: 1.25rem;
  font-weight: 600;
  padding: .9rem 2rem;
}

.navbar .btn-primary {
  height: 44px;
  display: inline-flex;
  width: auto;
  justify-content: space-around;
  align-items: center;
}

.btn-primary {
  background: var(--white);
  color: var(--black);
  border-color: var(--white);
}

.btn-primary img {
  margin-left: .35rem;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: #fff !important;
  border-color: #fff !important;
  color: var(--black);
}
.btn-primary svg {
  transition: all .5s;
}

.btn-primary:hover svg path,
.btn-primary:active svg path,
.btn-primary:focus svg path {
  stroke: var(--black) !important;
}

.btn-secondary {
  background: var(--black);
  color: var(--white);
  border-color: var(--black);
  height: 3.5rem;
  box-sizing: border-box;
  align-items: center;
  display: inline-flex;
  gap: 0.375rem;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  background-color: #1A1A1A !important;
  border-color: #1A1A1A !important;
}

.btn-secondary svg {
  transition: all .5s;
}

.btn-secondary svg path {
  stroke: #FFF !important;
}

.search-bar {
  display: flex;
  position: relative;
  transition: all .5s;
  justify-content: flex-end;
  height: 44px !important;
  width: 180px;
}

.search-bar-box {
  position: absolute;
  right: 0;
  top: 0;
}

.search-bar input {
  padding-left: 40px;
  color: #C6C6C6;
  font-size: 1rem;
  border: 0;
  height: 44px;
  border-radius: .5rem;
  transition: all .5s ease-in-out;
  position: relative;
  width: 180px;
  background: #1A1A1A;
}

.search-bar input:focus {
  width: 300px;
  box-shadow: none;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 9px;
}

.hero-sec {
  align-items: center;
  padding-top: 7rem;
  position: relative;
  min-height: calc(100dvh - 107px);
}

.video-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  left: 0;
  z-index: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: var(--black);
}

.hero-sec-text {
  padding-top: 0;
  padding-bottom: 8.75rem;
}

h1 {
  color: var(--white);
  font-size: 5.2rem;
  letter-spacing: -0.88px;
  font-weight: 500;
  margin-bottom: 2.5rem;
}

.hero-sec h1 {
  max-width: 550px;
  /* line-height: 4rem; */
}

.hero-sec h2.main-subtitle {
  color: var(--white);
  font-size: 2rem;
  margin-bottom: 3rem;
  letter-spacing: -0.88px;
}

.hero-sec h2.main-subtitle br {
  display: none;
}

.hero-sec .features {
  max-width: 550px;
  gap:2rem;
}

.hero-sec .feature {
  padding-bottom: 1rem;
}

.hero-sec .icon {
  display: block;
  margin-bottom: 1.2rem;
}

.hero-sec .title {
  font-weight: 800;
  font-size: 1.15rem;
  letter-spacing: 0.06rem;
  margin-bottom: 0.9rem;
}

.hero-sec .description {
  min-height: 72px;
}

.hero-sub-heading {
  background: #9DD9FB;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}

.hero-sec p.para-text {
  max-width: 480px;
  opacity: 0.8;
  font-size: 1.25rem;
  line-height: 2rem;
}

footer {
  background-color: var(--white);
  padding-top: 6rem;
  padding-bottom: 4.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-para-text {
  color: #5A5A5A;
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.26px;
  max-width: 280px;
}

.social-link a {
  display: inline-block;
  margin-right: 1rem;
  opacity: .5;
  transition: all .5s;
  width:1.125rem;
  margin-right:1rem;
}

.social-link a:hover {
  transform: scale(1.5);
  opacity: 1;
}

.footer-link h6 {
  color: var(--black);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.footer-link a {
  color: #5A5A5A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: block;
  margin-bottom: 1rem;
  text-decoration: none;
}

.footer-link a:hover,
.footer-link a:active,
.footer-link a:focus {
  color: var(--black);
}

.footer-hr {
  background: rgba(229, 229, 229, 0.60);
  height: 1px;
  margin-top: 6rem;
  margin-bottom: 2.5rem;
}

.policy-link a {
  display: inline-block;
  color: var(--black);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.13px;
  text-decoration-line: underline;
  margin-right: 2rem;
}

.simple-fast-secure {
  position: relative;
  padding: 6.25rem 0;
  /* background: url('../public/simple-bg.jpg'); */
  background-color: #fff;
  background-size: cover;
}
.simple-text-box {
  border-radius: 0.75rem;
  border: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.50);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.28), 0px 12px 56px 0px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(24px);
  margin-bottom: 3.5rem;
}
.simple-text-box  .grid-content {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
}

.simple-text-box:last-child {
  margin-bottom: 0;
}

.simple-text-box p {
  color: var(--black);
  font-size: 0.9375rem;
  font-style: normal;
  line-height: 144%;
  /* 1.35rem */
  letter-spacing: -0.01875rem;
  max-width: 17rem;
}

.simple-text-box p span {
  font-weight: 700;
}

.simple-text-box.one {
  position: absolute;
  top: 0;
  left: 0;
}

.simple-text-box.one .line {
  top: calc(100% + 1.25rem);
  left: 50%;
}

.simple-text-box .line {
  width: auto;
  height: auto;
  position: absolute;
}


.simple-text-box.two {
  position: absolute;
  top: 0;
  right: 0;
}

.simple-text-box.two .line {
  top: calc(100% + 1.25rem);
  right: 50%;
}

.simple-text-box.three {
  position: absolute;
  bottom: 0;
  left: 4.5rem;
}

.simple-text-box.three .line {
  left: calc(100% + 1.25rem);
  bottom: 50%;
}
.simple-video{
  max-width: 820px;
  padding: 10rem 0 6rem;
}

.discover-elys {
  background-color: var(--black);
  background-image: url('../public/discover-bg.jpg');
  background-size: cover;
  padding: 6.25rem 0;
  overflow: hidden;
}

.discover-box {
  width: 324px;
  margin: 0 auto;
  position: relative;
}
.discover-box img{
  max-width: 100%;
}

.discover-box-content {
  position: absolute;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0px 2.2966768741607666px 13.780060768127441px 0px rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(20px);
  padding: 1.5rem;
  color: var(--white);
  font-size: 0.84906rem;
  font-weight: 500;
  line-height: 134%;
  /* 1.13769rem */
  letter-spacing: -0.017rem;
  width: 15.7rem;
  border: 1px solid rgb(108 108 108 / 22%);
  transition: all .5s linear;
}

.discover-box-content img {
  margin-bottom: 1rem;
  width: 4.25rem;
}

.discover-box-content.active {
  border: 1px solid rgba(255, 255, 255, 0.95);
  background: rgba(255, 255, 255, 0.95);
  /* backdrop-filter: blur(16px); */
  color: var(--black);
}

.discover-box-content.left-top {
  transform: translate(-266px, -470px)
}

.discover-box-content.left-bottom {
  transform: translate(-266px, -277px);
}

.discover-box-content.right-top {
  transform: translate(338px, -470px)
}

.discover-box-content.right-bottom {
  transform: translate(338px, -277px);
}

/* .discover-box-content.center-bottom{
  bottom: 130px;
  left: 36px;
  display: none;
} */

.discover-box-btn{
  position: absolute;
  bottom: 130px;
  left: 36px;
  display: block;
  width: calc(100% - 72px);
  border-radius: .75rem;
  background: rgba(255, 255, 255, 0.10);
box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.16), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
backdrop-filter: blur(16px);
  color: var(--white);
  text-align: center;
  text-decoration: none;
  padding: .5rem;
  display: flex;
  font-size: 1rem;
  align-items: center;
}
.discover-box-btn .arrow{
  border-radius: 0.5rem;
  background: var(--white, #FFF);
  padding:.6rem;
  margin-right: .75rem;
}
.discover-box-btn .arrow svg{
  width: 1.5rem;
  height: 1.5rem;
}

.discover-box-content.center-top,
.discover-box-content.active {
  transform: translate(36px, -470px);
}

.discover-box .logo {
  position: absolute;
  top: 90px;
  left: 100px;
  z-index: 1;
}

.ultimate-experience {
  position: relative;
  padding: 6.25rem 0;
  background: url(../public/ultimate-experience-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--white);
}

.ultimate-experience .animate__fadeInUp {
  height: 100%;
}

.ultimate-experience .content-sec {
  position: relative;
  z-index: 1;
}

.ultimate-box {
  border-radius: 1.25rem;
  border: 1px solid #D9D9D9;
  background-color: #fff;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  transition: all .5s;
  height: 100%;
}
.ultimate-box:hover{
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 7px 40px 0px rgba(0, 0, 0, 0.10), 0px 30px 40px 0px rgba(0, 0, 0, 0.09), 0px 67px 40px 0px rgba(0, 0, 0, 0.05), 0px 120px 48px 0px rgba(0, 0, 0, 0.01), 0px 187px 52px 0px rgba(0, 0, 0, 0.00);
  background-color: var(--white);
}

.ultimate-box p {
  font-size: 0.9375rem;
}

.ultimate-box h4 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.03rem;
}

.ultimate-box img{
  transition: all .5s;
}
.ultimate-box:hover img{
  transform: scale(1.1);
}

/* .paired-bg {
  background-image: url(../public/paired-bg.png);
  background-position: center center;
  background-size: 95% 95%;
  background-repeat: no-repeat;
} */

.molecule-bg {
  position: relative;
}

.molecule-bg-1,
.molecule-bg-2 {
  position: absolute;
  z-index: 0;
}

.molecule-bg-1 {
  top: 40px;
}

.molecule-bg-2 {
  top: 0;
  right: 0px;
}

.molecule-content {
  margin-top: -100px;
}

.cutting-bg {
  margin-top: -2rem;
}


.bringing-world {
  background-color: var(--black);
  background-image: url('../public/bringings-world.png');
  background-size: cover;
  padding: 6.25rem 0;
  overflow: hidden;
}

.bringing-h-content {
  max-width: 38rem;
  margin: 0 auto;
  text-align: center;
}

.bringing-h-content p {
  opacity: 0.7;
  color: #fff;
}

.bringing-world-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bringing-world-box>div {
  max-width: 20rem;
  margin: 0 auto;
}

.bringing-world-box h4 {
  color: var(--white);
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  /* 145.455% */
  margin-bottom: 1.35rem;
}

.bringing-world-box p {
  color: rgba(255, 255, 255, 0.60);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
}


.market-based {
  position: relative;
  padding: 6.25rem 0;
  background: url('/public/market-based-bg.png') no-repeat #fff;
  background-size: cover;
}

.market-based-header {
  max-width: 28em;
  margin: 0 auto;
  text-align: center;
}

.market-based-box {
  border-radius: 1rem;
  background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(38.5px);
  padding: 2rem;
  transition: all .5s ease-in-out;
  transform: translate(0, 0);
  height: 100%;
  position: relative;
  top: 0;
  overflow: hidden;
}
.market-based-box::before{
  position: absolute;
  content: '';
  width:1000px;
  height: 1000px;
  transform: rotate(45deg);
 background: var(--black); 
 transition: all .5s ease-in-out;
 right: 100%;
 z-index: 0;
 top: -300px;
 border-radius: 50%;
 opacity: .5;
}
.market-based-box .market-based-content {
  opacity: .7;
  z-index: 1;
  position: relative;
}

/* .market-based-box:hover .market-based-content, */
.market-based-box.active .market-based-content {
  opacity: 1;
}

/* .market-based-box:hover, */
.market-based-box.active {
  transform: translate(-6rem, 0) rotate(3deg);
  /* background: var(--black); */
  color: var(--white);
}

/* .market-based-box:hover::before, */
.market-based-box.active::before {
  right: -400px;
  opacity: 1;
}

/* .market-based-box:hover h5, */
.market-based-box.active h5 {
  color: var(--white);
}

/* .market-based-box:hover .number, */
.market-based-box.active .number,
/* .market-based-box:hover p, */
.market-based-box.active p {
  color: var(--white);
  opacity: .7;
}

.market-based-box .number {
  color: #262626;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
}

.market-based-box h5 {
  color: #262626;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-top: 3.5rem;
  margin-bottom: 1.125rem;
}

.market-based-box p {
  color: var(--black);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.0225rem;
}

.market-based-row {
  display: flex;
  justify-content: center;
}

.market-based-row .animate__fadeInUp {
  height: 100%;
}

.market-based-row .col-md-3 {
  padding: 0;
  margin: 0 -2rem
}


.multi-token-model {
  background-color: var(--black);
  background-image: url('../public/multi-bg.png');
  background-size: cover;
  padding: 6.25rem 0 7.25rem 0;
  overflow: hidden;
}
.multi-token-model .animate__fadeInUp {
  height: 100%;
}
.multi-token-model h2{
  max-width: 33rem;
  margin: 0 auto 3.5rem;
}

.multi-token-box{
  border-radius: 1.25rem;
  background: rgba(26, 26, 26, 0.74);
  box-shadow: 0px 116px 164px 0px rgba(0, 0, 0, 0.90);
  backdrop-filter: blur(30px);
  padding: 2rem;
  height: 100%;
  transition: all .5s;
}
/* .multi-token-box:hover, */
 .multi-token-box.active{
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 7px 40px 0px rgba(0, 0, 0, 0.10), 0px 30px 40px 0px rgba(0, 0, 0, 0.09), 0px 67px 40px 0px rgba(0, 0, 0, 0.05), 0px 120px 48px 0px rgba(0, 0, 0, 0.01), 0px 187px 52px 0px rgba(0, 0, 0, 0.00);
  background-color: var(--white);
  border-color: transparent;
  transform: translateY(-1rem);
}
.multi-token-box p{
  color: #C5C5C5;
font-size: 1.25rem;
font-style: normal;
font-weight: 400;
line-height: 150%;
margin-top: 2rem;
transition: all .5s;
}
.multi-token-box svg{
  transition: all .5s ease-in-out;
}
/* .multi-token-box:hover p, .multi-token-box:hover h4,  */
.multi-token-box.active p, .multi-token-box.active h4{
  color: var(--black);
}
.multi-token-box:hover p, .multi-token-box.active p{
  opacity: .7;
}
/* .multi-token-box:hover svg,  */
.multi-token-box.active svg{
  transform: rotate(360deg) scale(1.1);
}
/* .multi-token-box:hover svg rect,  */
.multi-token-box.active svg rect{
  fill: var(--black);
}

/* .multi-token-box:hover svg path,  */
.multi-token-box.active svg path{
  fill: var(--white);
}

.multi-token-box h4{
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 1.5rem;
  color: var(--white, #FFF);
font-size: 1.375rem;
font-weight: 600;
letter-spacing: 0.0625rem;
text-transform: uppercase;
transition: all .5s;
}

/* CSS */
.my-element {
  opacity: 0;
  visibility: hidden;
}

.remove-fade-css {
  -webkit-animation-fill-mode:inherit;
  animation-fill-mode:inherit;
}

/* Apply the animation when the element is visible */
.my-element.animate__fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 1s;
  opacity: 1;
  visibility: visible;
}

.back-to-top-box {
  position:absolute;
  bottom: 0;
  right: 30px;
  height: 3.5rem;
  width: 3.5rem;
  z-index: 999;
  border-radius: 3rem;
  background: var(--white, #FFF);
  backdrop-filter: blur(6px);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  transition: all .5s;
  opacity: 0;
  display: flex;
}
.back-to-top-box.show{
  opacity: 1;
  bottom: 30px;
}
.light-bg .back-to-top-box.show{
  background-color: var(--black);
}
.light-bg .back-to-top-box.show svg path{
  stroke: var(--white);
}

.sustainable {
  position: relative;
  padding: 6.25rem 0;
  background: url('/public/sustainable-bg.jpg') no-repeat #fff;
  background-size: cover;
}

.fee-sec{
  border-radius: 2.5rem;
background: #FFF;
backdrop-filter: blur(4px);
padding: 2.5rem 3rem 0;
flex-shrink: 0;
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
gap: 2.25rem;
justify-content: center;
align-items: center;
}
.fee-sec-box{
  display: flex;
padding: 1.25rem;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 1.5rem;
border: 1px solid #E5E5E5;
backdrop-filter: blur(110px);
}

.fee-sec-box.bridging{
  z-index: 1;
  position: relative;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.00) 100%);
}

.fee-sec-box .img{
  min-height: 136px;
}
.fee-sec p{
text-align: center;
font-size: .875rem;
font-style: normal;
font-weight: 500;
line-height: 1.375rem;
margin-top: 1rem;
margin-bottom: 2rem;
display: flex;
min-height: 46px;
align-items: center;
justify-content: center;
}

.building-future{
  position: relative;
  padding: 6.25rem 0;
  background: #FAFAFA;
  background-size: cover;
}

.building-future .market-based-header{
  max-width: 40rem;
}

.building-future-box{
  border-radius: 1rem;
border: 1px solid #E5E5E5;
background: var(--white, #FFF);
box-shadow: 4px 22px 56px 0px rgba(0, 0, 0, 0.06), 0px 6px 10px 0px rgba(0, 0, 0, 0.03);
transition: all .5s;
overflow: hidden;
}

.building-future-box:hover{
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 7px 40px 0px rgba(0, 0, 0, 0.10), 0px 30px 40px 0px rgba(0, 0, 0, 0.09), 0px 67px 40px 0px rgba(0, 0, 0, 0.05), 0px 120px 48px 0px rgba(0, 0, 0, 0.01), 0px 187px 52px 0px rgba(0, 0, 0, 0.00);
}
.building-future-box img{
  transition: all .5s;
}
.building-future-box:hover img{
  transform: rotateY(10deg) rotateZ(3deg) translateY(1rem);
}

.building-future-box h3{
  margin-top: 4rem;
  font-size: 2rem;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.04rem;
color: var(--black);
}
.building-future-box p{
  color: var(--black, #000);
text-align: center;
font-size: 1rem;
letter-spacing: -0.01125rem;
opacity: .67;
max-width: 28rem;
}

.building-future-box img{
  border-radius: 1rem;
margin-top: 1rem;
}
.core-value{
  background: var(--black);
}
.core-value h3{
  color:var(--white);
}
.core-value p{
  color: var(--white);
  opacity: .7;
}

.sustainable-ani{
  max-width: 800px;
  margin:0 auto;
  padding-right: 4rem;
}


@media (min-width:1366px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width:448px) {
  .hero-sec-text {
    padding-left: 2.5rem;
  }
}

@media (max-width:499px) {
  .hero-sec {
    margin-top: 0;
    padding-top: 0;
  }

  .hero-sec .features {
    max-width: 300px;
    flex-direction: column;
    gap: 0;
  }

  .video-bg {
    position: relative;
    height: auto;
    z-index: 0;
    padding-top: 85.06%;
  }
}

@media (max-width:767px) {
  h2,.h2{
    font-size: 2rem;
  }

  .hero-sec {
    min-height: auto;
  }

  .simple-text-box.one,
  .simple-text-box.two,
  .simple-text-box.three {
    position: relative;
    left: unset;
    top: unset;
    right: unset;
  }

  .simple-text-box .line {
    display: none;
  }

  .simple-text-box {
    margin-bottom: 2rem;
  }

  /* .market-based-box:hover, */
  .market-based-box.active {
    transform: translate(0, 0) rotate(1deg);
    background: var(--black);
    color: var(--white);
  }
  .discover-box {
   transform: scale(.7);
  }
  .fee-sec{
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
  justify-content:space-between;
  align-items: center;
  padding: 1.5rem;
  }

  .hero-sec h1{
    font-size: 4.5rem;
    /* line-height: 1; */
  }
  .hero-sec h2.main-subtitle br {
    display: inline;
  }

  /* gradient from bottom to transparent */
  .video-bg:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000000 100%);
  }
  .video-bg > *,
  .video-bg > * > * {
    display: flex;
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0px;
    left: 0px;
    inset: 0;
  }
  .search-bar{
    display: none !important;
  }
  .nav-link{
    padding: .75rem 0 !important;
    font-size: 1.25rem;
  }
  .simple-video{
    max-width: 820px;
    padding: 0 0 2rem;
  }
  .back-to-top-box{
    width: 2.5rem;
    height: 2.5rem;
  }
  .sustainable-ani{
    padding-right: 2rem;
  }
}

@media (max-width:991px) {

  .footer-link {
    margin-bottom: 2rem;
  }

  .footer-hr {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .nav-link {
    padding: .75rem 0 !important;
  }

  .search-bar {
    width: 100%;
  }

  .search-bar-box {
    width: 100%;
  }

  .search-bar input,
  .search-bar input:focus {
    width: 100%;

  }

  .navbar-collapse.collapse {
    max-height: calc(100vh - 107px);
    overflow: auto;
  }

  .fee-sec{
    gap: 2rem;
    justify-content:center;
    align-items: center;

  }
  .fee-sec-box{
    padding: .5rem;
  }
}

@media (min-width:992px) and (max-width:1199px) {

  .search-bar,
  .search-bar input {
    width: 60px;
  }


}

